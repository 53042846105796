.loading-div{
    background: linear-gradient(90deg, var(--red-linear));
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-div img{
    animation: loading-animation 3s linear infinite;
}

@keyframes loading-animation {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}